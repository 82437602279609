<template>
  <img alt="Northern Rockies SAAA" src="./assets/badge.jpg" style="width:50%;height:50%">
  <HelloWorld msg="Welcome to Your Vue.js App"/>
  <NextEvent msg="Coming Soon"/>
</template>

<script>
   // import HelloWorld from './components/HelloWorld.vue'
    import NextEvent from './components/NextEvent.vue'

export default {
  name: 'App',
  components: {
      //HelloWorld,
      NextEvent
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
