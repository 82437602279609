<template>
    <div class="bgimg">
        <div class="topleft ts">
            <p>Northern Rockies (SAAA)</p>
        </div>
        <div class="middle">
            <h1 class="ts">COMING SOON</h1>
            <hr />
            <strong class="ts">
                <p style="font-size:50px">Albany Scottish Festival </p>
                <p style="font-size:50px">August 19th, 2023 </p>
                <p class="ts" id="demo" style="font-size:50px" />
            </strong>
        </div>
        <div class="bottomleft ts">
            <p>We are working on a new and improved website.  Please check back for updates.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NextEvent',
        props: {

        }
    }

    // Set the date we're counting down to
    var countDownDate = new Date("August 19, 2023 00:00:00").getTime();

    // Update the count down every 1 second
    var countdownfunction = setInterval(function () {

        // Get todays date and time
        var now = new Date().getTime();

        // Find the duration between now an the count down date
        var duration = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(duration / (1000 * 60 * 60 * 24));
        var hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((duration % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        document.getElementById("demo").innerHTML = days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ";

        // If the count down is over, write some text
        if (duration < 0) {
            clearInterval(countdownfunction);
            document.getElementById("demo").innerHTML = "EXPIRED";
        }
    }, 1000);

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
